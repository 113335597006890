/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getLogo } from '../../../../state/ducks/Common/Common-Selectors';
import { getCurrentBrandId } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

// These are backup in case the logo has not been added in ContentStack.
const logos = {
    '18F': 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/flowers-stacked-logo.png',
    '18B': 'https://cdn1.1800baskets.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/18b-logo-v2.svg',
    CCO: 'https://cdn1.cheryls.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/cco-logo-v2.svg',
    HD: 'https://cdn1.harryanddavid.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/hd-logo-v2.svg',
    WLF: 'https://cdn1.wolfermans.com/wcsstore/RAPIDStorefrontAssetStore/images/ab/wlf-logo-v2.svg',
    SCH: 'https://images.contentstack.io/v3/assets/blt1fa614f4f56206fd/blt1268b11a91fd4ef3/5ebb9a2f6d085375dccc20cf/simplychocolate.png',
    TPF: 'https://images.contentstack.io/v3/assets/blt5423a503ab876a21/bltdb8352f490cf1bde/5e843b3fed2db72f23e87e47/popcorn.png',
    SY: 'https://images.contentstack.io/v3/assets/blt0281495b20b504a0/blt46fd307c3061c78d/5f0c010bceebbf7e87c30459/Stockyards_logo_new.svg',
    FBQ: 'https://images.contentstack.io/v3/assets/blte4aebfe2fb7fcc9d/blta7725399a4bc9f66/5f0484eabece672e221df519/FruitBouquets.png',
    BRY: 'https://images.contentstack.io/v3/assets/blt22a4279bb3e9cfce/blt3da066ed42ac5829/5f11e308c31823015df6ee2a/SharisLogo_r.svg',
    VC: 'https://images.contentstack.io/v3/assets/blta522552136812831/blte9dce71ff37e47ae/61a08c3846713833c753941f/VC_Logo_transparent_(1).png',
};

// These are backup in case the logo has not been added in ContentStack.
const logosMobile = {
    '18F': 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt566093fa31adb04e/64244a74095db610b0d52dcd/18F-new.svg',
    '18B': 'https://images.contentstack.io/v3/assets/bltc699f5c4977942f7/blte7a32787b2cc8ee9/64120f0743153734d14742fa/1800Baskets_Logo_compactMOB-svg.svg',
    CCO: 'https://images.contentstack.io/v3/assets/blt9f0a9533818afd99/blt877836b996afd5ee/5ea088a2f923401150072ae2/cco-logo-v2.svg',
    HD: 'https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltff290eac2ecfd735/5ed945d6ff1cb239b9500ec6/hd-logo-2f.svg',
    FBQ: 'https://images.contentstack.io/v3/assets/blte4aebfe2fb7fcc9d/blt5e16de6cd30990c9/60d5dd219648f1650fe17f4b/fruit_bouquets_logo_275x90.jpg',
    WLF: 'https://images.contentstack.io/v3/assets/blt72739032ac3bdcc6/blt18bf64e2a4fd5673/5edec4ffbf4ae52c761ed7bf/WF_bakery.svg',
    SCH: 'https://images.contentstack.io/v3/assets/blt1fa614f4f56206fd/blt0625bcbc0a51091a/5eda46fb444d25730a19e5e7/simplychocolate.png',
    TPF: 'https://images.contentstack.io/v3/assets/blt5423a503ab876a21/blted7c4e3c1adeb81b/5eb2bbb16509f3370a5a8928/tpf-logo-v2.svg',
    SY: 'https://images.contentstack.io/v3/assets/blt0281495b20b504a0/blt7c3b4fda06d8672f/5f0c44219be1ce01c562a296/Stockyards_logo_new_(1).svg',
    BRY: 'https://images.contentstack.io/v3/assets/blt22a4279bb3e9cfce/blt3da066ed42ac5829/5f11e308c31823015df6ee2a/SharisLogo_r.svg',
    VC: 'https://images.contentstack.io/v3/assets/blta522552136812831/blt4ad2e327d32b7758/61b78309a000c90ecc6e1822/VC_Logo_w_tag.svg',
};

const styles = (theme) => ({
    logo: {
        height: '46px',
        width: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '150px',
        },
    },
});

const BrandLogo = (props) => {
    const {
        logo, classes, withLink, brand, deviceType,
    } = props;

    let brandLogo = logo || logos[brand];
    if (deviceType === 'mobile' && logosMobile[brand]) {
        brandLogo = logosMobile[brand];
    }

    if (withLink) {
        return (
            <img
                className={classes.logo}
                src={brandLogo}
                alt="Logo"
            />
        );
    }

    const homeUrl = (typeof window !== 'undefined') ? window?.location?.origin : '/';
    return (
        <a href={homeUrl} id="logo">
            <img
                className={classes.logo}
                src={brandLogo}
                alt="Logo"
            />
        </a>
    );
};

BrandLogo.propTypes = {
    classes: PropTypes.object.isRequired,
    withLink: PropTypes.bool,
    logo: PropTypes.string,
    brand: PropTypes.string,
    deviceType: PropTypes.string,
};

BrandLogo.defaultProps = {
    withLink: false,
    brand: '',
    logo: '', // This fix console browser warnings
    deviceType: 'desktop',
};
const mapStateToProps = (state) => ({
    logo: getLogo(state),
    brand: getCurrentBrandId(state),
});

export default compose(connect(mapStateToProps, null), withStyles(styles))(BrandLogo);
