/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import {
    array, bool, func, shape, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getFirstLastName } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { useAuth } from '../../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

const useStyles = makeStyles((theme) => ({
    header: ({ presentationFamily }) => ({
        width: '335px',
        background: presentationFamily === 'flower' ? (theme.palette.cms?.mobileBgHeader || theme.palette?.bgHeader) : theme.palette.cms?.white,
        minHeight: '50px',
        boxShadow: '2px 2px 4px rgba(47, 47, 47, 0.15)',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
        justifyContent: 'space-between',
    }),
    headerContainer: {
        minHeight: '50px',
        position: 'relative',
        zIndex: 9,
        background: '#fff',
    },
    headerLogo: ({ presentationFamily }) => ({
        display: 'flex',
        '& img': {
            height: presentationFamily === 'flower' ? 48 : 28,
        },
    }),
    closeSection: {
        display: 'flex',
        alignItems: 'center',
    },
    signInContainer: ({ presentationFamily }) => ({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '10px',
        marginTop: '10px',
        textTransform: 'uppercase',
        '& span': {
            fontSize: '9px',
            color: presentationFamily === 'flower' ? (theme.palette.cms?.mobileMenuTextColor || theme.palette?.mobileMenuColor) : '',
        },
        '& img': {
            width: '17px',
        },
    }),
    closeIcon: {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: '14px',
        },
        '& svg > path': {
            fill: theme.palette.cms?.mobileMenuTextColor || theme.palette?.mobileMenuColor,
        },
    },
    signInLink: {
        display: 'contents',
        '& svg > path': {
            fill: theme.palette.cms?.mobileMenuTextColor || theme.palette?.mobileMenuColor,
        },
    },
}));

const MenuHeader = ({
    menu, handleClickMenu, logo, isAuthenticated,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    const classes = useStyles({ presentationFamily });
    const getFullName = useSelector(getFirstLastName);
    const { loginByRedirect } = useAuth();

    const headerMenuIcon = {
        common: {
            signInIcon: (
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8C9.2091 8 11 6.20914 11 4C11 1.79086 9.2091 0 7 0ZM4 4C4 2.34315 5.34315 1 7 1C8.6569 1 10 2.34315 10 4C10 5.65685 8.6569 7 7 7C5.34315 7 4 5.65685 4 4ZM2.00873 9C0.90315 9 0 9.8869 0 11C0 12.6912 0.83281 13.9663 2.13499 14.7966C3.41697 15.614 5.14526 16 7 16C7.41085 16 7.8155 15.9811 8.2105 15.9427C7.97298 15.6472 7.7654 15.3266 7.59233 14.9855C7.39798 14.9951 7.20041 15 7 15C5.26489 15 3.74318 14.636 2.67262 13.9534C1.62226 13.2837 1 12.3088 1 11C1 10.4467 1.44786 10 2.00873 10H7.59971C7.7826 9.6422 8.0035 9.3071 8.2572 9H2.00873ZM8 12.5C8 14.9853 10.0147 17 12.5 17C14.9853 17 17 14.9853 17 12.5C17 10.0147 14.9853 8 12.5 8C10.0147 8 8 10.0147 8 12.5ZM14.8532 12.146L14.8557 12.1486C14.9026 12.196 14.938 12.2505 14.9621 12.3086C14.9861 12.3667 14.9996 12.4303 15 12.497V12.5V12.503C14.9996 12.5697 14.9861 12.6333 14.9621 12.6914C14.9377 12.7504 14.9015 12.8056 14.8536 12.8536L12.8536 14.8536C12.6583 15.0488 12.3417 15.0488 12.1464 14.8536C11.9512 14.6583 11.9512 14.3417 12.1464 14.1464L13.2929 13H10.5C10.2239 13 10 12.7761 10 12.5C10 12.2239 10.2239 12 10.5 12H13.2929L12.1464 10.8536C11.9512 10.6583 11.9512 10.3417 12.1464 10.1464C12.3417 9.9512 12.6583 9.9512 12.8536 10.1464L14.8532 12.146Z" />
                </svg>
            ),
            closeIcon: (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                </svg>
            ),
        },
    };

    const fallBackSignInIcon = headerMenuIcon.common.signInIcon;
    const closeIcon = headerMenuIcon.common.closeIcon;
    const fallBackUserIcon = (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4589 9.9961C12.4939 9.9961 13.3329 10.8351 13.3329 11.8702V12.3497C13.3329 13.0949 13.0666 13.8156 12.582 14.3818C11.2742 15.9097 9.2849 16.6637 6.66373 16.6637C4.04216 16.6637 2.05382 15.9094 0.748567 14.3809C0.265433 13.8151 0 13.0955 0 12.3515V11.8702C0 10.8351 0.839042 9.9961 1.87406 9.9961H11.4589ZM11.4589 11.2461H1.87406C1.5294 11.2461 1.25 11.5255 1.25 11.8702V12.3515C1.25 12.7979 1.40926 13.2297 1.69914 13.5691C2.74356 14.7922 4.38179 15.4137 6.66373 15.4137C8.94565 15.4137 10.5853 14.7922 11.6324 13.5689C11.9231 13.2292 12.0829 12.7968 12.0829 12.3497V11.8702C12.0829 11.5255 11.8035 11.2461 11.4589 11.2461ZM6.66373 0C8.9649 0 10.8304 1.86548 10.8304 4.16667C10.8304 6.46786 8.9649 8.33335 6.66373 8.33335C4.36252 8.33335 2.49705 6.46786 2.49705 4.16667C2.49705 1.86548 4.36252 0 6.66373 0ZM6.66373 1.25C5.0529 1.25 3.74705 2.55584 3.74705 4.16667C3.74705 5.7775 5.0529 7.08335 6.66373 7.08335C8.27457 7.08335 9.5804 5.7775 9.5804 4.16667C9.5804 2.55584 8.27457 1.25 6.66373 1.25Z" />
        </svg>
    );

    const shortenFit = (s) => (s.length <= 6 ? s : `${s.substr(0, 6)}…`);
    const isMenuLinks = menu.filter((menuLink) => menuLink?.menu_links);

    let isMenuLinkInclude = isMenuLinks?.filter?.((items) => items?.menu_link?.filter?.((item) => item));

    if (isMenuLinkInclude?.length === 0) {
        isMenuLinkInclude = [];
        isMenuLinks?.forEach?.((item) => {
            if (item?.menu_links?.menu_link?.length > 1) {
                item?.menu_links?.menu_link?.forEach?.((links) => {
                    isMenuLinkInclude.push(links);
                });
            } else {
                isMenuLinkInclude.push(item?.menu_links?.menu_link?.[0]);
            }
        });
    }

    const SignInLink = isMenuLinkInclude.filter((item) => item?.link?.title?.includes('Login') || item?.link?.title?.includes('Sign In'));
    const myAccount = isMenuLinkInclude.filter((item) => item?.link?.title?.includes('My Account'));
    const getLogin = (
        <>
            {SignInLink.length > 0 && (
                <div className={classes.signInContainer}>
                    <div onClick={loginByRedirect} className={classes.signInLink} role="presentation" id="loginButton">
                        {fallBackSignInIcon}
                        <span>Sign In</span>
                    </div>
                </div>
            )}
        </>
    );

    const getUserName = (
        <>
            {myAccount.length > 0 && (
                <div className={classes.signInContainer}>
                    <Link onClick={handleClickMenu} className={classes.signInLink} href={myAccount[0].link.href}>
                        {fallBackUserIcon}
                        <span>{shortenFit(getFullName?.firstName || '')}</span>
                    </Link>
                </div>
            )}
        </>
    );

    return (
        <div className={classes.headerContainer}>
            <div className={classes.header}>
                <div className={classes.headerLogo}>
                    <img src={logo?.image?.url} alt="logo" />
                </div>
                <div className={classes.closeSection}>
                    <div className={classes.headerIcons}>
                        {isAuthenticated ? (<>{getUserName}</>) : getLogin}
                    </div>
                    <span
                        role="presentation"
                        onClick={handleClickMenu}
                        className={classes.closeIcon}
                    >
                        {closeIcon}
                    </span>
                </div>
            </div>
        </div>
    );
};

MenuHeader.propTypes = {
    menu: array,
    handleClickMenu: func,
    logo: shape({
        image: shape({
            url: string,
        }),
    }),
    isAuthenticated: bool,
};

MenuHeader.defaultProps = {
    menu: [],
    handleClickMenu: () => {},
    logo: {},
    isAuthenticated: false,
};

export default MenuHeader;
