/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, array, string, bool, func,
} from 'prop-types';

import Button from '@material-ui/core/Button';

import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import SearchMenuImg from '../../images/searchmenu.png';
import CheckoutHeaderIcon from '../../images/checkout-header.png';
import { getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { useAuth } from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

const useStyles = makeStyles((theme) => ({
    signInLinkHeader: {
        color: '#000',
        width: '110%',
        display: 'block',
        textAlign: 'center',
        textDecoration: 'none !important',
        fontSize: '12px !important',
        marginLeft: '10px !important',
        paddingLeft: '5px',
        marginTop: '3px',
    },
    menuIcon: {
        color: theme.palette.colorIconHeader,
        width: '30px',
    },
    clsHeightWidth: {}, // do not delete used for css specificity
    flowersMenuIcon: {
        '& img': {
            '&$clsHeightWidth': {
                marginBottom: '3px',
            },
        },
    },
    rootButton: {
        minWidth: '40px',
        padding: '0 7px',
        textAlign: 'left',
    },
    labelButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        margin: '0',
    },
    checkoutNortonIcon: {
        color: theme.palette.colorIconHeader,
        width: '60px',
        display: 'block',
        paddingLeft: '9px',
        alignSelf: 'center',
    },
}));

// TODO: replace these with feature flag
const menuHeaderBrands = ['HD', 'WLF', 'SY', 'CCO'];

const TopHeaderMenu = ({
    menu,
    menuIcon,
    brand,
    searchInputTerm,
    cssTitleIcon,
    isAuthenticated,
    handleClickMenu,
}) => {
    const classes = useStyles();
    const presentationFamily = useSelector(getPresentationFamily);
    const { loginByRedirect } = useAuth();

    const pathname = useLocation().pathname;
    const  renderMenuIcon = () => {
        if (menuHeaderBrands.includes(brand.code)) {
            return <MenuIcon className={classes.menuIcon} />;
        }

        return <img className={`${classes.menuIcon}`} src={SearchMenuImg} alt="Menu" width="24" height="24" />;
    };

    const  renderTopHeaderMenu = () => {
        if (pathname) {
            if (pathname.match(/checkout/) && menuHeaderBrands.includes(brand.code)) {
                if (pathname.match(/checkout/) && !isAuthenticated) {
                    const isMenuLinks = menu.filter((menuLink) => menuLink?.menu_links);
                    const isMenuLinkInclude = isMenuLinks?.[0]?.menu_links?.menu_link.filter((menuLink) => menuLink);
                    const SignInLink = isMenuLinkInclude.filter((menuLink) => menuLink?.link?.title?.includes('Login'));
                    if (SignInLink.length > 0) {
                        return (
                            <Button className={classes.signInLinkHeader} onClick={() => loginByRedirect()}>
                                SIGN IN
                            </Button>
                        );
                    }
                    return '';
                }
                return (
                    <img
                        className={classes.checkoutNortonIcon}
                        src={CheckoutHeaderIcon}
                        alt="Checkout-Header"
                    />
                );
            }

            if (menuIcon.name) {
                // menu icon passed from CMS
                return (
                    <button type="button" onClick={() => handleClickMenu()} className={`${menuIcon.className} ${presentationFamily === 'flower' && classes.flowersMenuIcon}`}>
                        <img src={menuIcon.url} className={classes.clsHeightWidth} alt={menuIcon.description} aria-hidden width="24" height="24" />
                        <span>{menuIcon.description}</span>
                    </button>
                );
            }

            return (
                <Button
                    className="IconButton"
                    onClick={() => handleClickMenu()}
                    classes={{
                        root: classes.rootButton,
                        label: classes.labelButton,
                    }}
                >
                    {renderMenuIcon(brand, classes, searchInputTerm)}
                    <span className={cssTitleIcon} data-test="nav-menu-open-button-text">
                        Menu
                    </span>
                </Button>
            );
        }
        return '';
    };
    return renderTopHeaderMenu();
};

TopHeaderMenu.propTypes = {
    menu: array.isRequired,
    searchInputTerm: string,
    brand: object.isRequired,
    menuIcon: object,
    cssTitleIcon: string.isRequired,
    isAuthenticated: bool.isRequired,
    handleClickMenu: func,
};
TopHeaderMenu.defaultProps = {
    searchInputTerm: '',
    menuIcon: { name: null },
    handleClickMenu: () => {},
};

export default TopHeaderMenu;
