/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, array, bool, func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ConnectedSearch from '../../../GraphqlComponents/GraphqlHeader/Search';
import MenuBuilder from './MenuBuilder/MenuBuilder';
import TopHeaderMenu from './TopHeaderMenu';
import LeftWelcomeClose from './LeftWelcomeClose';

const useStyles = makeStyles((theme) => {
    const mobileMenuBG = theme.palette.mobileMenuBG ? theme.palette.mobileMenuBG : null;
    return ({
        searchForm: {
            padding: '10px',
            backgroundColor: theme.palette.cta1,
        },
        contDrawer: {
            overflowX: 'hidden',
        },
        drawer: {
            width: '100%',
            background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            height: '100%',
            fontFamily: theme.typography?.fontFamily,
            minWidth: '280px',
            maxWidth: '300px',
            fontWeight: '400',
        },
    });
});

const LeftMenuBot = ({
    isBot,
    menu,
    menuIcon,
    brand,
    searchInputTerm,
    isMobileMenuV2Enabled,
    isAuthenticated,
    cssTitleIcon,
    open,
    handleClickMenu,
    keeperData,
}) => {
    const classes = useStyles();
    const widget = menu?.find((widgetData) => widgetData?.widgets);
    const search = widget?.widgets?.name === 'Search';
    return (
        <>
            <TopHeaderMenu
                menu={menu}
                menuIcon={menuIcon}
                brand={brand}
                searchInputTerm={searchInputTerm}
                cssTitleIcon={cssTitleIcon}
                isAuthenticated={isAuthenticated}
                handleClickMenu={handleClickMenu}
            />
            <Drawer classes={{ paper: classes.contDrawer }} open={open} onClose={() => handleClickMenu()}>
                <div className={classes.drawer}>
                    {search && <LeftWelcomeClose brand={brand} handleClickMenu={handleClickMenu} />}
                    <div className={classes.searchForm}>
                        <ConnectedSearch
                            languageId={-1}
                            brand={brand}
                            typeSearchBox="menu"
                            searchInputTerm={searchInputTerm}
                            searchButtonClick={handleClickMenu}
                        />
                    </div>
                    <MenuBuilder
                        isBot={isBot}
                        menu={menu}
                        brand={brand}
                        handleClose={handleClickMenu}
                        target="Mobile"
                        isMobileMenuV2Enabled={isMobileMenuV2Enabled}
                        keeperData={keeperData}
                    />
                </div>

            </Drawer>
        </>
    );
};

LeftMenuBot.propTypes = {
    menu: array.isRequired,
    searchInputTerm: string,
    brand: object.isRequired,
    isMobileMenuV2Enabled: bool,
    isBot: bool,
    menuIcon: object,
    isAuthenticated: bool.isRequired,
    cssTitleIcon: string.isRequired,
    open: bool,
    handleClickMenu: func,
    keeperData: object,
};
LeftMenuBot.defaultProps = {
    searchInputTerm: '',
    menuIcon: { name: null },
    isBot: false,
    isMobileMenuV2Enabled: false,
    open: false,
    handleClickMenu: () => {},
    keeperData: {},
};

export default LeftMenuBot;
