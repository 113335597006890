/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import  { shape, string, func } from 'prop-types';

import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    const mobileMenuBG = theme.palette.mobileMenuBG ? theme.palette.mobileMenuBG : null;
    return ({
        closeMenuRaised: {
            background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            color: theme.palette.white,
            fontSize: '16px',
            width: '100%',
            padding: '10px 9px',
            textTransform: 'initial',
            fontWeight: 'normal',
            borderRadius: 'initial',
            '& hover': {
                background: mobileMenuBG ? mobileMenuBG.leftMenuBG : '#909090',
            },
        },
        closeTextMenuRaised: {
            color: 'white',
            display: 'inline-block',
            width: '90%',
            textAlign: 'left',
            fontWeight: '500',
        },
        closeIconMenuRaised: {
            display: 'inline-block',
            width: '10%',
            textAlign: 'right',
        },
    });
});

// TODO: replace these with feature flag
const menuHeaderBrands = ['HD', 'WLF', 'SY', 'CCO'];

const LeftWelcomeClose = ({ brand, handleClickMenu }) => {
    const classes = useStyles();
    return (menuHeaderBrands.includes(brand.code) && (
        <Button
            className={classes.closeMenuRaised}
            aria-label="Close Menu"
            onClick={() => handleClickMenu()}
            data-test="nav-menu-close-button"
        >
            <span className={classes.closeTextMenuRaised}>{brand['display-name']}</span>
            <Close className={classes.closeIconMenuRaised}>close</Close>
        </Button>
    )
    );
};

LeftWelcomeClose.propTypes = {
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    handleClickMenu: func,
};

LeftWelcomeClose.defaultProps = {
    handleClickMenu: () => {},
};
export default LeftWelcomeClose;
